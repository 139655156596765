<template>
	<Dialog :dialog="extendCheckoutDialog" :dialog-width="640">
		<template v-slot:title>Extend Issue {{ type }}</template>
		<template v-slot:body>
			<v-form
				ref="extendCheckoutForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="onSubmit"
			>
				<v-row class="extend-checkout-dialog">
					<v-col md="12" class="my-auto py-0">
						<label :for="`return-on-${uniqFieldId}`" class="btx-label required"
							>Extend Asset Issue Till</label
						>
					</v-col>
					<v-col md="12" class="py-0">
						<DateTimePicker
							hide-details
							:disabled="extendCheckout.indefinitely || pageLoading"
							:loading="pageLoading"
							:id="`return-on-${uniqFieldId}`"
							required
							placeholder="Extend Asset Issue Till"
							v-model="extendCheckout.return_on"
						></DateTimePicker>
					</v-col>
					<v-col md="12" class="py-0">
						<v-checkbox
							dense
							hide-details
							label="Ignore clashing reservation when extending check out"
							:true-value="1"
							:false-value="0"
							class="mt-3 mb-0 p-0"
							v-model="extendCheckout.ignore_conflict"
						></v-checkbox>
					</v-col>
					<v-col md="12" class="py-0">
						<v-checkbox
							dense
							hide-details
							label="Issue indefinitely"
							:true-value="1"
							:false-value="0"
							class="mt-3 mb-0 p-0"
							v-model="extendCheckout.indefinitely"
						></v-checkbox>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				:loading="pageLoading"
				:disabled="!formValid || pageLoading"
				depressed
				color="blue darken-4"
				tile
				v-on:click="onSubmit()"
			>
				Save
			</v-btn>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";
import DateTimePicker from "@/view/components/DateTimePicker";

export default {
	name: "extend-checkout-template",
	title: "Extend Checkout Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		extendCheckoutDialog: {
			type: Boolean,
			default: false,
		},
		checkoutUuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			extendCheckout: {
				return_on: null,
				indefinitely: 0,
				ignore_conflict: 0,
			},
		};
	},
	watch: {
		extendCheckoutDialog(param) {
			if (param) {
				this.init();
				this.pageLoading = true;
			}
		},
	},
	components: {
		Dialog,
		DateTimePicker,
	},
	methods: {
		init() {
			const today = new Date();
			this.extendCheckout = new Object({
				return_on: this.formatDateTimeRaw(today),
				indefinitely: 0,
				ignore_conflict: 0,
			});

			ApiService.get(`${this.endpoint}/${this.typeUuid}/checkout/${this.checkoutUuid}`)
				.then(({ data }) => {
					this.extendCheckout.return_on = this.formatDateTimeRaw(data.return_on);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.extendCheckoutForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.extendCheckoutForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			ApiService.patch(
				`${this.endpoint}/${this.typeUuid}/extend-checkout/${this.checkoutUuid}`,
				this.extendCheckout
			)
				.then(() => {
					this.$emit("success");
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
};
</script>
